import React, {useEffect} from 'react';
import {Navigate, useLocation, useParams} from 'react-router-dom';
import {useAuth} from "../../hooks/useAuth";
import {getQueryParam} from "../../general/util";
import Loader from "./Loader";
import {useDispatch} from "react-redux";
import { setIsLogismos, setLocation} from "../../redux/slices/protectedRouteSlice";

const ProtectedRoute = ({element: Component}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const {loggedIn} = useAuth();
	const {accommodation} = useParams();
	
	const type = getQueryParam('type');
	const res = getQueryParam('res');
	
	const logismos = getQueryParam('logismos') === 'true';
	
	useEffect(() => {
		if (!loggedIn) {
			dispatch(setLocation(location));
		}
		if (logismos) {
			dispatch(setIsLogismos(logismos));
		}
	}, [loggedIn, location, dispatch]);
	
	if ((type || res) && !loggedIn) {
		return <Loader/>;
	} else if (!loggedIn) {
		return <Navigate to={{pathname: `/${accommodation}/auth/2fa`, search: location.search}} replace/>;
	}
	
	return <Component/>;
};

export default ProtectedRoute;
