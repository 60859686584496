import {BASE_URL} from "./constants";
import moment from "moment";
import store from "../redux/store";

// Load Custom Fonts
export const loadFonts = async (fontIds) => {
	const fontNames = {}; // Object to store font names
	
	// Load each font and assign names
	const fontPromises = fontIds.map(async (id, index) => {
		const fontName = `CustomFont${index}`;
		const fontUrl = `${BASE_URL}/attachments/${id}`;
		
		const newStyle = document.createElement('style');
		newStyle.appendChild(document.createTextNode(`
      @font-face {
        font-family: '${fontName}';
        src: url('${fontUrl}') format('opentype');
      }
    `));
		document.head.appendChild(newStyle);
		
		if ('fonts' in document) {
			await document.fonts.load(`1em ${fontName}`);
		}
		fontNames[index === 0 ? 'body' : 'header'] = fontName;
	});
	
	await Promise.all(fontPromises);
	return fontNames; // Return the object with font names
};

export const getContentById = (webAppStructure, item) => {
	const lang = getQueryParam('lang');
	const content = webAppStructure.outlets.find(outlet => outlet.id === item.contentId) ||
		webAppStructure.outletLists.find(list => list.id === item.contentId) ||
		webAppStructure.widgets.find(widget => widget.id === item.contentId) ||
		webAppStructure.screens.find(screen => screen.screen === item.contentId);
	
	return item.contentType === 'screen' ? {
		...item, ...content,
		title: content?.multilingualName?.[lang] || content?.multilingualName?.en || item.contentName,
		description: content?.description?.[lang] || content?.description?.en,
		image: content?.portraitImages?.[0],
	} : {
		...content,
		...item,
		image: content?.portraitImages?.[0],
		title: content?.systemName?.[lang] || content?.systemName?.en || content?.name?.[lang] || content?.name?.en,
		description: content?.description?.[lang] || content?.description?.en
	};
};

export const getQueryParam = (paramName) => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	return urlParams.get(paramName);
}

export const setQueryParam = (paramName, value) => {
	const searchParams = new URLSearchParams(window.location.search);
	searchParams.set(paramName, value);
	// Update the URL in the address bar without reloading the page
	window.history.pushState({}, '', `${window.location.pathname}?${searchParams}`);
}

export const toTitleCaseWithSpaces = (str) => {
	// Insert a space before all caps and trim whitespace
	return str
		// First replace underscores or hyphens with spaces
		.replace(/[_-]/g, ' ')
		// Insert a space before all caps and make sure first character is uppercase
		.replace(/([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g, '$1$4 $2$3$5')
		// Convert first character of each word to uppercase
		.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const setLocalStorageWithTimer = (key, value, expirationTime) => {
	const item = {
		value,
		expirationTime: Date.now() + expirationTime,
	};
	
	localStorage.setItem(key, JSON.stringify(item));
	
	setTimeout(() => {
		localStorage.removeItem(key);
	}, expirationTime);
};

export const getLocalStorageWithTimer = (key) => {
	const item = localStorage.getItem(key);
	
	if (item) {
		const {value, expirationTime} = JSON.parse(item);
		
		if (expirationTime > Date.now()) {
			return value;
		} else {
			localStorage.removeItem(key);
		}
	}
	
	return null;
};


export const getDate = {
	today: () => moment().format('YYYY-MM-DD'),
	tomorrow: () => moment().add(1, 'days').format('YYYY-MM-DD'),
	format: (date) => moment(date).format('YYYY-MM-DD'),
}

export const durationToHoursAndMinutes = (minutes) => {
	const hours = Math.floor(minutes / 60);
	const remainingMinutes = minutes % 60;
	
	if (hours > 0 && remainingMinutes > 0) {
		return `${hours}h${String(remainingMinutes).padStart(2, '0')}m`;
	} else if (hours > 0) {
		return `${hours}h`;
	} else {
		return `${remainingMinutes}m`;
	}
}

// export const filterFutureSlots = (slots, isObject = false, selectedDate, bookingTimeThreshold) => {
// 	console.log(bookingTimeThreshold)
// 	const currentDate = moment().format('YYYY-MM-DD');
// 	const currentTime = moment();
//
// 	console.log(currentTime)
// 	if (currentDate !== selectedDate) {
// 		return slots
// 	}
// 	return slots.filter(slot => {
// 		console.log(slot)
// 		const slotTime = isObject ? slot.start : slot; // Determine if slot is an object or a string
// 		const slotDateTime = moment(`${currentDate}T${slotTime}`);
// 		return slotDateTime.isAfter(currentTime);
// 	});
// }

export const filterFutureSlots = (slots, isObject = false, selectedDate, bookingTimeThreshold) => {
	
	// Provide a default value for bookingTimeThreshold if it's undefined
	// Defaulting to 0 means no additional threshold time is added.
	const effectiveThreshold = bookingTimeThreshold || 0;
	
	const currentDate = moment().format('YYYY-MM-DD');
	const currentTime = moment();
	
	// If the selected date is not today, return all slots because they are inherently future slots.
	if (currentDate !== selectedDate) {
		return slots;
	}
	
	// Add the booking time threshold to the current time
	// Convert threshold to minutes, assuming it is provided in minutes.
	const thresholdTime = currentTime.add(effectiveThreshold, 'minutes');
	
	return slots.filter(slot => {
		const slotTime = isObject ? slot.start : slot; // Determine if slot data includes start time or just time.
		const slotDateTime = moment(`${selectedDate}T${slotTime}`); // Construct the datetime for the slot.
		// Check if the slot time is after the threshold time.
		return slotDateTime.isAfter(thresholdTime);
	});
};

export const maxAttendantsNumber = (slot, service, adults, children) => {
	// Assuming slot and service are objects and have an attendantsNumber field.
	// This function now returns a number indicating the maximum additional attendants
	// that can be accommodated. If the slot is not an object (which seems to be an unexpected case),
	// it returns a large number to indicate no practical limit.
	
	if (typeof slot === "object") {
		const maxFromService = Number(service?.attendantsNumber ?? 0);
		const currentInSlot = Number(slot?.attendantsNumber ?? 0);
		const additionalNeeded = Number(adults) + Number(children);
		return maxFromService - currentInSlot - additionalNeeded;
	}
	// If there's no slot object, return a large number to indicate no limit,
	// or consider throwing an error if a slot object is always expected.
	return Infinity; // Indicates no limit.
}

export const attendantsAllowed = (adults, children, selectedSlot, service) => {
	const maxAllowed = maxAttendantsNumber(selectedSlot, service, adults, children);
	
	// If overbooking is allowed for the service, we may bypass the max attendants check, its for the last booking.
	if (service?.overbooking) {
		return true; // Overbooking allowed, bypass the check.
	}
	return maxAllowed >= 0;
}

export const calculateRemainingPlaces = (selectedSlot, service) => {
	return maxAttendantsNumber(selectedSlot, service, 0, 0);
}

export const isDateDisabled = (day, availableStartDate, availableEndDate, disableTomorrow, enableToday) => {
	if (day.isBefore(availableStartDate, 'day') || day.isAfter(availableEndDate, 'day')) {
		return true;
	}
	if (disableTomorrow && day.isSame(moment().add(1, 'days'), 'day')) {
		return true;
	}
	if (!enableToday && day.isSame(moment(), 'day')) {
		return true;
	}
	return false;
};

export const findAvailableDateRangeForBook = (user, outlet) => {
	let availableStartDate = moment().subtract(1, 'days');
	let availableEndDate = moment().add(1, 'years');
	let maxNextDays = null;
	let disableTomorrow = false;
	let enableToday = true;
	
	const now = moment();
	
	// Guest-specific adjustments
	if (user?.reservationNumber) {
		if (outlet?.tags?.includes("allowReservationsOnlyDuringStayDates")) {
			availableStartDate = moment(user.arrivalDate).format('YYYY-MM-DD');
			availableEndDate = moment(user.departureDate).format('YYYY-MM-DD');
		} else if (outlet?.tags?.includes("allowReservationsOnlyDuringStayDatesExcludingDepartureDate")) {
			availableStartDate = moment(user.arrivalDate).format('YYYY-MM-DD');
			availableEndDate = moment(user.departureDate).subtract(1, 'days').format('YYYY-MM-DD');
		} else if (outlet?.tags?.includes("allowReservationsOnlyForCheckinDate")) {
			availableStartDate = moment(user.arrivalDate).format('YYYY-MM-DD');
			availableEndDate = availableStartDate;
		}
	}
	
	// Check for availableDaysForBooking and other related tags in richTags
	const availableDaysForBookingTag = outlet?.richTags?.find(tag => tag.richTagName === "availableDaysForBooking");
	if (availableDaysForBookingTag) {
		maxNextDays = parseInt(availableDaysForBookingTag.richTag.maxNextDays, 10);
		const disableTomorrowIfNowEarlierThan = availableDaysForBookingTag.richTag.disableTomorrowIfNowEarlierThan;
		const disableTomorrowIfNowLaterThan = availableDaysForBookingTag.richTag.disableTomorrowIfNowLaterThan;
		const enableTodayIfNowEarlierThan = availableDaysForBookingTag.richTag.enableTodayIfNowEarlierThan;
		const enableTodayIfNowLaterThan = availableDaysForBookingTag.richTag.enableTodayIfNowLaterThan;
		
		// Dynamic handling for specific attributes like boardType
		if (user?.reservationNumber) {
			for (const key in user) {
				if (user.hasOwnProperty(key) && availableDaysForBookingTag.richTag[`maxNextDays_${key}_${user[key]}`]) {
					maxNextDays = parseInt(availableDaysForBookingTag.richTag[`maxNextDays_${key}_${user[key]}`], 10);
				}
			}
		}
		
		
		// Disable today if current time is not earlier/later than specified
		if ((enableTodayIfNowEarlierThan && now.isAfter(moment(enableTodayIfNowEarlierThan, 'HH:mm'))) ||
			(enableTodayIfNowLaterThan && now.isBefore(moment(enableTodayIfNowLaterThan, 'HH:mm')))) {
			enableToday = false;
		}
		
		// Disable tomorrow if current time is earlier/later than specified
		if (disableTomorrowIfNowEarlierThan && now.isBefore(moment(disableTomorrowIfNowEarlierThan, 'HH:mm'))) {
			disableTomorrow = true;
		}
		
		if (disableTomorrowIfNowLaterThan && now.isAfter(moment(disableTomorrowIfNowLaterThan, 'HH:mm'))) {
			disableTomorrow = true;
		}
		
		// maxNextDays logic
		if (!isNaN(maxNextDays)) {
			const startDay = enableToday ? moment() : (disableTomorrow ? moment().add(2, 'days') : moment().add(1, 'days'));
			let enabledDays = 0;
			let endDay = startDay.clone();
			while (enabledDays < maxNextDays) {
				if (!(disableTomorrow && endDay.isSame(moment().add(1, 'days'), 'day'))) {
					enabledDays++;
				}
				endDay.add(1, 'day');
			}
			availableEndDate = endDay.subtract(1, 'day');
		}
	}
	return {availableStartDate, availableEndDate, disableTomorrow, enableToday, maxNextDays};
}

export const mergeQueryParams = (baseUrl, pathParams) => {
	// Function to replace placeholders in the path with actual values from pathParams
	const replacePathParams = (url, params) => {
		return url?.replace(/\{(\w+)\}/g, (match, key) => {
			return params[key] || match; // replace with value from params or leave unchanged if no match
		});
	};
	
	// Replace path parameters based on the pathParams object
	baseUrl = replacePathParams(baseUrl, pathParams);
	
	// Parse the baseUrl, using the current location as the base for a potentially relative URL
	const url = new URL(baseUrl, window.location.href);
	
	// Extract query parameters from both the provided baseUrl and the current window URL
	const additionalParams = new URLSearchParams(url.search);
	const existingParams = new URLSearchParams(window.location.search);
	
	// Initially set all additionalParams, these might be overwritten by existingParams
	for (const [key, value] of additionalParams.entries()) {
		existingParams.set(key, value);
	}
	
	// Overwrite with existingParams to ensure they take precedence
	for (const [key, value] of new URLSearchParams(window.location.search).entries()) {
		if (additionalParams.has(key)) {
			existingParams.set(key, value); // Ensures existing param values take precedence
		}
	}
	
	// Handle the hash part of the URL
	const hash = url.hash;
	
	// Construct the final URL
	if (baseUrl.startsWith('http') || baseUrl.startsWith('//')) {
		// For absolute URLs, rebuild it with the new query parameters
		return `${url.origin}${url.pathname}?${existingParams.toString()}${hash}`;
	} else {
		// For relative URLs, attach the new query parameters directly
		return `${baseUrl.split('?')[0]}?${existingParams.toString()}${hash}`;
	}
};

export const detectDeviceEnvironment = () => {
	const userAgent = window.navigator.userAgent.toLowerCase();
	const isAndroidDevice = userAgent.includes('android');
	const isiOSDevice = userAgent.includes('iphone') || userAgent.includes('ipad');
	const isWebView = ((isiOSDevice || isAndroidDevice) && !userAgent.includes('safari') && !userAgent.includes('chrome'));
	const isInStandaloneMode = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
	
	return {
		isAndroidDevice,
		isiOSDevice,
		isWebView,
		isMobileDevice: isAndroidDevice || isiOSDevice,
		isInStandaloneMode
	};
}

export const redirectToWhatsApp = () => {
	// Access the current state from the Redux store
	const state = store.getState();
	
	const user = state.user.data;
	const whatsAppNumber = state.properties?.properties?.values?.chatapps?.whatsappNumber;
	
	// Check if user data and WhatsApp number exist
	if (!whatsAppNumber) {
		alert('WhatsApp number is not available.');
		return;
	}
	
	const { reservationNumber, lastName, roomNumber, firstName } = user || {};
	
	let message = `Please%20send%20us%20your%20details%20first!`;
	
	// Append room, reservation, and name information if available
	if (roomNumber) {
		message += `%20Room:%20${roomNumber}`;
	}
	
	if (reservationNumber) {
		message += `%20Reservation%20ID:%20${reservationNumber}`;
	}
	
	if (firstName && lastName) {
		message += `%20Name:%20${encodeURIComponent(firstName)}%20${encodeURIComponent(lastName)}`;
	}
	
	// Construct the full URL for WhatsApp redirection
	const fullURL = `https://api.whatsapp.com/send?phone=${whatsAppNumber}&text=${message}`;
	
	// Redirect the user to WhatsApp
	window.location.href = fullURL;
};

// Utility to check if a URL is internal
export const isInternalURL = (url) => {
	// Treat URLs starting with `/` as internal
	if (url.startsWith('/')) {
		return true;
	}
	
	// Treat URLs starting with "exclusivi.okgini.com" or "https://exclusivi.okgini.com" as internal
	if (
		url.startsWith('https://exclusivi.okgini.com') ||
		url.startsWith('exclusivi.okgini.com')
	) {
		return true;
	}
	
	// For all other cases, return false
	return false;
};


export const formatChildDetails = (childDetails = []) => {
	if (!childDetails?.length) return '';
	
	return childDetails
		.filter(child => child.name || child.age)
		.map((child) => {
			const name = child.name?.trim();
			const age = child.age;
			
			if (name && age !== '' && !isNaN(age)) {
				const suffix = parseInt(age, 10) === 1 ? 'year old' : 'years old';
				return `${name} (${age} ${suffix})`;
			}
			if (name) return name;
			if (age !== '' && !isNaN(age)) {
				const suffix = parseInt(age, 10) === 1 ? 'year old' : 'years old';
				return `${age} ${suffix}`;
			}
			return null;
		})
		.filter(Boolean)
		.join(', ');
};







