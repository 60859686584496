import {createTheme} from '@mui/material/styles';
import "../assets/fonts/fonts.css";

const breakpoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		lg: 1200,
		xl: 1536,
	},
};

const palette = {
	primary: {
		main: '#956F56',
	},
	secondary: {
		main: '#F9F4F1',
	},
	background: {
		default: '#FCFCFC',
	},
	text: {
		primary: '#1C1C1A',
	},
	common: {
		black: '#1C1C1A',
		white: '#ffffff'
	},
};

const typography = {
	fontFamily: 'Avenir Next Condensed',
	h1: {
		fontFamily: 'Canela Light',
		fontSize: '1.863rem',
	},
	h2: {
		fontFamily: 'Canela Light',
		fontSize: '1rem',
	},
	h3: {
		fontFamily: 'Canela Light',
		fontSize: '1rem',
	},
	h4: {
		fontFamily: 'Canela Light',
		fontSize: '1rem',
	},
	h5: {
		fontFamily: 'Canela Light',
		fontSize: '1.1rem',
	},
	body: {
		fontFamily: 'Canela Light',
		fontSize: '1rem',
	},
	body1: {
		fontFamily: 'Avenir Next Condensed',
		fontSize: '1rem',
		
	},
	body2: {
		fontFamily: 'Canela Light',
		fontSize: '1rem',
	}
}

export const createCustomTheme = (customColors = {}, customFonts = {}) => {
	const themeTypography = { ...typography };
	
	if (customFonts.body) {
		// Apply custom body font
		themeTypography.fontFamily = `${customFonts.body}`
		themeTypography.body.fontFamily = `${customFonts.body}`;
		themeTypography.body1.fontFamily = `${customFonts.body}`;
		themeTypography.body2.fontFamily = `${customFonts.body}`;
		themeTypography.h1.fontFamily = `${customFonts.body}`;
		themeTypography.h2.fontFamily = `${customFonts.body}`;
		themeTypography.h3.fontFamily = `${customFonts.body}`;
		themeTypography.h4.fontFamily = `${customFonts.body}`;
		themeTypography.h5.fontFamily = `${customFonts.body}`;
		
	}
	if (customFonts.header) {
		// Apply custom header font
		const headerFontFamily = `${customFonts.header}`;
		themeTypography.fontFamily = headerFontFamily;
		themeTypography.body1.fontFamily = headerFontFamily;
	}
	
	return createTheme({
		breakpoints,
		palette: {
			...palette,
			headerBackgroundColor: customColors.headerBackgroundColor || palette.common.white,
			burgerMenuColor: customColors.burgerMenuColor || palette.primary.main,
			arrowBackColor: customColors.burgerMenuColor || palette.primary.main,
			textColor: customColors.textColor || palette.primary.main,
			footerBackgroundColor: customColors.footerBackgroundColor || palette.primary.main,
			containerBackgroundColor: customColors.containerBackgroundColor || palette.background.default,
			paperBackgroundColor: customColors.paperBackgroundColor || palette.background.default,
			listItemTextColor:  customColors.listItemTextColor || palette.primary.main,
			listItemBackgroundColor: customColors.listItemBackgroundColor || palette.common.white,
		},
		typography: themeTypography,
		components: {
			MuiBottomNavigationAction: {
				styleOverrides: {
					root: {
						color: customColors.footerTextColor || palette.background.default,
						'&:hover': {
							color: customColors.footerTextColor || palette.background.default,
						},
						'&.Mui-selected': {
							color: customColors.footerTextColor || palette.background.default,
						},
					},
				},
			},
			MuiCssBaseline: {
				styleOverrides: {
					html: {
						overflowX: 'hidden',
						scrollBehavior: 'smooth',
					},
					body: {
						margin: 0,
						padding: 0,
						overflowX: 'hidden',
						width: '100vw',
						boxSizing: 'border-box',
						backgroundColor: customColors.containerBackgroundColor || palette.background.default,
					},
					'#root, .App': {
						minHeight: '100vh',
						display: 'flex',
						flexDirection: 'column',
						overflowX: 'hidden',
					},
				},
			},
		},
		customStyles: {
			list: {},
			slider: {},
			grid: {
				display: 'grid',
				gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
				gap: '15px',
				padding: '20px',
			},
			cards: {
				gap: '20px',
				padding: '20px',
			},
			buttons: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				gap: '10px',
				padding: '20px',
			},
			sizes: {
				small: {
					fontSize: '0.8rem',
					padding: '8px',
				},
				medium: {
					fontSize: '1rem',
					padding: '16px',
				},
				large: {
					fontSize: '1.2rem',
					padding: '24px',
				},
			},
		},
	});
};

const defaultTheme = createCustomTheme();
export default defaultTheme;
