import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	location: false,
	status: 'idle',
	isLogismos: false,
}

const protectedRouteSlice = createSlice({
	name: 'protectedRoute',
	initialState,
	reducers: {
		setLocation(state, action) {
			state.location = action.payload;
		},
		setIsLogismos(state, action) {
			state.isLogismos = action.payload;
		},
	},
});

export const { setLocation, setIsLogismos } = protectedRouteSlice.actions;

export default protectedRouteSlice.reducer;
