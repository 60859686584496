import {configureStore} from "@reduxjs/toolkit";
import translationsSlice from "./slices/translationsSlice";
import propertiesSlice from "./slices/propertiesSlice";
import launcherInfoSlice from "./slices/launcherInfoSlice";
import webAppStructureSlice from "./slices/webAppStructureSlice";
import userSlice from "./slices/userSlice";
import bookingsSlice from "./slices/bookingsSlice";
import webMenusSlice from "./slices/webMenusSlice";
import navigationSlice from "./slices/navigationSlice";
import protectedRouteSlice from "./slices/protectedRouteSlice";
import casinoSlice from "./slices/casinoSlice";

const store = configureStore({
	reducer: {
		translations: translationsSlice,
		properties: propertiesSlice,
		launcherInfo: launcherInfoSlice,
		webMenus: webMenusSlice,
		webAppStructure: webAppStructureSlice,
		user: userSlice,
		bookings: bookingsSlice,
		navigation: navigationSlice,
		protectedRoute: protectedRouteSlice,
		casino: casinoSlice
	},
});

export default store;
