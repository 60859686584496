import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getHelper } from "../../general/apiHelper";
import { BASE_URL } from "../../general/constants";

export const fetchPromos = createAsyncThunk(
	'casino/fetchPromos',
	async ({ accommodation, profileId, coupLott }, { rejectWithValue }) => {
		try {
			const params = new URLSearchParams();
			if (accommodation) params.append('accommodationId', accommodation);
			if (profileId) params.append('profileId', profileId);
			if (coupLott) params.append('coupLott', coupLott);
			
			const response = await getHelper(
				`${BASE_URL}/loyaltyCard/logismos/getPromos?${params.toString()}`,
				{}
			);
			
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error fetching promos');
		}
	}
);

const initialState = {
	coupons: [],
	groupedLotteries: {},
	status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
	error: null,
};

const casinoSlice = createSlice({
	name: 'casino',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPromos.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchPromos.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(fetchPromos.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.error = null;
				
				const groupedLotteries = {}; // Lotteries will be grouped
				let flatCoupons = []; // Coupons will be stored as a flat list
				
				action.payload.forEach((promo) => {
					const { promo_id, coup_lott, promo_series_descr, actualdate_issued } = promo;
					
					if (coup_lott === 'C') {
						// Coupons: Add directly to flat array
						flatCoupons.push(promo);
					} else if (coup_lott === 'L') {
						// Lotteries: Group by promo_series_descr and promo_id
						if (!groupedLotteries[promo_series_descr]) {
							groupedLotteries[promo_series_descr] = {};
						}
						if (!groupedLotteries[promo_series_descr][promo_id]) {
							groupedLotteries[promo_series_descr][promo_id] = [];
						}
						groupedLotteries[promo_series_descr][promo_id].push(promo);
					}
				});
				
				// Sort Coupons by `actualdate_issued` (Latest First)
				flatCoupons.sort((a, b) => new Date(b.actualdate_issued) - new Date(a.actualdate_issued));
				
				// Sort Lotteries in each Group by `actualdate_issued`
				Object.keys(groupedLotteries).forEach(series => {
					Object.keys(groupedLotteries[series]).forEach(promoId => {
						groupedLotteries[series][promoId].sort((a, b) =>
							new Date(b.actualdate_issued) - new Date(a.actualdate_issued)
						);
					});
				});
				
				state.coupons = flatCoupons; // Store sorted coupons as a flat array
				state.groupedLotteries = groupedLotteries; // Store sorted lotteries as a nested object
			});
		
	},
});

export const {} = casinoSlice.actions;
export default casinoSlice.reducer;
